import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      dark: "#141621", // Original dark shade
      main: "#333652", // Vibrant primary color
      main600: "#141621",
      main500: "#1f2031", // Mid-dark shade
      main400: "#292b42", // Useful for gradients or shadows
      main300: "#333652", // Mid shade
      main200: "#5c5e75", // Lighter mid shade
      main100: "#adafba", // Near light shade
      main50: "#90f828", // Very
    },
    text: {
      primary: "#E9EAEC",
    },
    secondary: {
      main: "#FAD02C",
      secondary: "#90ADC6",
    },
    success: {
      main: "#C8E4B2",
    },
  },
  typography: {
    fontFamily: "Righteous, sans-serif",
    body1: {
      fontSize: "18px",
    },
    h3: {
      fontSize: "1.7rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
    h4: {
      fontSize: "1.4rem",
      "@media (min-width:600px)": {
        fontSize: "2rem",
      },
    },
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
