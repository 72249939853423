// src/components/Hero.js
import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { hero } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      style={{
        backgroundColor: "#333652",
        backgroundImage: `url(${hero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "right",
      }}
    >
        <Grow in={true} timeout={1200}>
          <Box p={4} display="flex" height="100%" flexDirection="column" justifyContent="center" alignItems="start" textAlign="left">
            <Typography variant="h3" gutterBottom style={{ fontWeight: "900" }}>
             Social pokies
            </Typography>
            <Typography variant="h4">
             Play online games for free
            </Typography>
          </Box>
        </Grow>
    </Box>
  );
}

export default Hero;
