import {SnappySpy, Nosedive, KnightRide, JimboJump, Alfy, Jumpy, BattleFish, OddOneOut, StickyGoo, SaucerDodge, HeroArcher, SirBottomtight, KingdomFight, SudokuClassic, SnakesLadders, SnakeKing, EnchantedWaters, ZombiesCantJump2, RampageRacer, BowlingStars, CityCricket, BubbleWipeout, EscapeRun, MergeMania} from "../assets";

 const games = [
    {
      id: 1,
      title: 'SnappySpy',
      image: SnappySpy,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SysZvGUt_ye',
    },

    {
      id: 24,
      title: 'Merge Mania',
      image: MergeMania,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/hfPOimYqY',
    },

    {
      id: 13,
      title: 'Kingdom Fight',
      image: KingdomFight,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SyfxJ3a75Cr',
    },
    {
      id: 2,
      title: 'Nosedive',
      image: Nosedive,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SJXVafJP51Q',
    },

    {
      id: 23,
      title: 'Escape Run',
      image: EscapeRun,
      iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/Skz4pzkDqyX',
    },
    {
        id: 3,
        title: 'Knight Ride',
        image: KnightRide,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rkYbNLTIT-x',
      },
      {
        id: 4,
        title: 'Jimbo Jump',
        image: JimboJump,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/BkXW1a__',
      },
      {
        id: 5,
        title: 'Alfy',
        image: Alfy,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/BJAqNMC7T',
      },

      {
        id: 20,
        title: 'Bowling Stars',
        image: BowlingStars,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/BkdJhTX50B',
      },
      {
        id: 8,
        title: 'Odd One Out',
        image: OddOneOut,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/Bk4ML6470x',
      },

      {
        id: 21,
        title: 'City Cricket',
        image: CityCricket,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/HJP4afkvqJQ',
      },
      {
        id: 9,
        title: 'Sticky Goo',
        image: StickyGoo,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/B1Gbjphf_gZ',
      },
      {
        id: 10,
        title: 'Saucer Dodge',
        image: SaucerDodge,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/H1AN6fkwqJ7',
      },
      {
        id: 11,
        title: 'Hero Archer',
        image: HeroArcher,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/r1u7J3TmqCS',
      },
      {
        id: 12,
        title: 'Sir Bottomtight',
        image: SirBottomtight,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rJDlAKHbdV',
      },
      {
        id: 14,
        title: 'Sudoku Classic',
        image: SudokuClassic,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/SJgx126Qc0H',
      },
      {
          id: 15,
          title: 'Snakes&Ladders',
          image: SnakesLadders,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rJWyhp79RS',
        },
        {
          id: 16,
          title: 'Snake King',
          image: SnakeKing,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/ryTknTX5AS',
        },
        {
          id: 17,
          title: 'Enchanted Waters',
          image: EnchantedWaters,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/HJskh679Cr',
        },

      {
        id: 6,
        title: 'Jumpy',
        image: Jumpy,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/HkO-wf8F_Jx',
      },
      {
        id: 7,
        title: 'Battle Fish',
        image: BattleFish,
        iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/ry3vtunu',
      },
        {
          id: 18,
          title: 'Zombies Can`t Jump 2',
          image: ZombiesCantJump2,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/rkxMV8TI6Wg',
        },
        {
          id: 19,
          title: 'Rampage Racer',
          image: RampageRacer,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/p7HOjYF4O',
        },
        {
          id: 22,
          title: 'Bubble-Wipeout',
          image: BubbleWipeout,
          iframeUrl: 'https://zv1y2i8p.play.gamezop.com/g/H1AN6fkwqJ7',
        },
  ];
  
  export default games;